.client-request-card-selects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 8px;
  .ant-select {
    flex: 1;
  }
}
