.client-requests-view-page {
  // TODO handle globally
  font-size: 16px;
  h2,
  h3 {
    margin: 0;
    color: #4a3557;
  }

  height: 100%;
  background: var(--clr-background);
  padding: var(--gaps-5) var(--gaps-2);

  hr {
    width: 100%;
    opacity: 0.5;
    margin: 0 auto;
    border: 1px solid var(--clr-borders);
    border-top: none;

    &:last-of-type {
      display: none;
    }
  }

  &__truck,
  &__estimate-tasks,
  &__estimate-tools,
  &__estimate-supplies {
    padding: var(--gaps-2) 0;
  }

  &__cta {
    @include query(m) {
      display: none;
    }
  }

  &__timeline {
    left: 100%;
    position: fixed;
    transition: left 0.5s;
    padding: var(--gaps-2);
    background: var(--clr-background);
    width: calc(100% - 2 * var(--gaps-2));

    @include modifier(visible) {
      left: var(--gaps-2);
    }

    @include query(m) {
      left: 0;
      max-width: 376px;
      position: relative;
    }

    @include query(1500px) {
      max-width: 576px;
    }
  }

  &__estimate-supplies {
    li {
      max-width: 768px;
      @include extend(flex);

      .ui-markdown {
        min-width: 50px;
        max-width: 50px;
      }

      & > div.flex-auto {
        flex: none;

        .ui-icon {
          height: 0.875em;
          width: 0.875em;
        }
      }
    }
  }

  &__estimate-tasks {
    li {
      @include extend(flex);

      .ui-markdown {
        min-width: 50px;
        max-width: 50px;
      }
    }
  }

  @include query(m) {
    padding: var(--gaps-5) var(--gaps-4);
  }
}

p.ui-p,
ul {
  margin: 0 !important;
}
.client-requests-view-page {
  font-size: 16px;
}

.loader {
  height: 100%;
  @include extend(flex items-center justify-center);
}
