.trip-card-table {
  th {
    vertical-align: top;
    text-align: left;
  }
  td {
    vertical-align: top;
    text-align: left;
  }
}
