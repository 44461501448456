.ant-select.blue-select {
  color: white;
  .ant-select-selector {
    border-color: var(--sc-blue-rgb);
    background-color: var(--sc-blue-rgb);
  }
  .ant-select-arrow {
    color: white;
  }
  &:hover {
    color: var(--sc-blue-rgb);
    .ant-select-selector {
      border-color: var(--sc-blue-rgb) !important;
      background-color: var(--sc-blue-rgba-1);
    }
    .ant-select-arrow {
      color: var(--sc-blue-rgb);
    }
  }

  &:focus-within {
    color: var(--sc-blue-rgb);
    .ant-select-selector {
      border-color: var(--sc-blue-rgb) !important;
      background-color: var(--sc-blue-rgba-1);
    }
    .ant-select-arrow {
      color: var(--sc-blue-rgb);
    }
  }
}

.blue-select-item .ant-select-item-option-selected {
  background-color: var(--sc-blue-rgba-1);
}

.select-blue-bg.ant-select .ant-select-selector {
  background-color: var(--sc-blue-rgba-1) !important;
}
