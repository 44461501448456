.clickable {
  cursor: pointer;
}

.opacity-50 {
  opacity: 0.5;
}

.full-w {
  width: 100%;
}

.w-250 {
  width: 250px;
}

.full-h {
  height: 100%;
}

.danger:not(.ant-select),
.danger.ant-select .ant-select-selector {
  border: 1px solid var(--sc-red-rgb);
  color: var(--sc-red-rgb) !important;
  background-color: var(--sc-red-rgba-1) !important;
}

.warning:not(.ant-select),
.warning.ant-select .ant-select-selector {
  border: 1px solid var(--sc-yellow-rgb);
  color: var(--sc-yellow-rgb) !important;
  background-color: var(--sc-yellow-rgba-1) !important;
}

.relative {
  position: relative;
}

.hidden {
  display: none;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/2 {
  width: 50%;
}

.w-full {
  width: 100%;
}

.max-w-1\/2 {
  max-width: 50%;
}

.lh-revert {
  line-height: revert;
}

.text-one-line {
  white-space: nowrap;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // display: inherit;
}

.ant-table-tbody .text-one-line {
  white-space: normal;
}

.text-center {
  text-align: center;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
  user-select: none;
}

.notion-iframe-modal {
  top: 5vh;
}

.notion-iframe-modal .ant-modal-content {
  padding: 0;
  border-radius: 25px;
}
