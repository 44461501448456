@import './Modals/Modals.styles.scss';

.card-extra {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  .ant-select {
    flex-grow: 1;
    width: 250px;
  }
}

.card-body-overflow-x {
  .ant-card-body {
    overflow-x: auto;
  }
}

.contacts-card-body {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  margin-right: 8px;
}
