/************************/
/* Element Self Padding */
/************************/

/* Overall padding */
.pad-1 {
  padding: 8px;
}
.pad-2 {
  padding: 16px;
}
.pad-3 {
  padding: 24px;
}
.pad-1-i {
  padding: 8px !important;
}
.pad-2-i {
  padding: 16px !important;
}
.pad-3-i {
  padding: 24px !important;
}

/* Top padding */
.pad-t-1 {
  padding-top: 8px;
}
.pad-t-2 {
  padding-top: 16px;
}
.pad-t-3 {
  padding-top: 24px;
}
.pad-t-1-i {
  padding-top: 8px !important;
}
.pad-t-2-i {
  padding-top: 16px !important;
}
.pad-t-3-i {
  padding-top: 24px !important;
}

/* Left padding */
.pad-l-1 {
  padding-left: 8px;
}
.pad-l-2 {
  padding-left: 16px;
}
.pad-l-3 {
  padding-left: 24px;
}
.pad-l-1-i {
  padding-left: 8px !important;
}
.pad-l-2-i {
  padding-left: 16px !important;
}
.pad-l-3-i {
  padding-left: 24px !important;
}

/* Bottom padding */
.pad-b-1\/2 {
  padding-bottom: 4px;
}
.pad-b-1 {
  padding-bottom: 8px;
}
.pad-b-2 {
  padding-bottom: 16px;
}
.pad-b-3 {
  padding-bottom: 24px;
}
.pad-b-1-i {
  padding-bottom: 8px !important;
}
.pad-b-2-i {
  padding-bottom: 16px !important;
}
.pad-b-3-i {
  padding-bottom: 24px !important;
}

/* Right padding */
.pad-r-1\/2 {
  padding-right: 4px;
}
.pad-r-1 {
  padding-right: 8px;
}
.pad-r-2 {
  padding-right: 16px;
}
.pad-r-3 {
  padding-right: 24px;
}
.pad-r-1-i {
  padding-right: 8px !important;
}
.pad-r-2-i {
  padding-right: 16px !important;
}
.pad-r-3-i {
  padding-right: 24px !important;
}

/* Horizontal padding */
.pad-h-1\/2 {
  padding-right: 4px;
  padding-left: 4px;
}
.pad-h-1 {
  padding-right: 8px;
  padding-left: 8px;
}
.pad-h-2 {
  padding-right: 16px;
  padding-left: 16px;
}
.pad-h-3 {
  padding-right: 24px;
  padding-left: 24px;
}
.pad-h-1-i {
  padding-right: 8px !important;
  padding-left: 8px !important;
}
.pad-h-2-i {
  padding-right: 16px !important;
  padding-left: 16px !important;
}
.pad-h-3-i {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

/* Vertical padding */
.pad-v-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.pad-v-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.pad-v-3 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.pad-v-1-i {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.pad-v-2-i {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.pad-v-3-i {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

/*************************/
/* Element Child Padding */
/*************************/
.child-pad-r-1\/2 > * {
  padding: 4px;
}
.child-pad-r-1\/2.nl > *:last-child {
  padding-right: initial !important;
}

.child-pad-r-1 > * {
  padding: 8px;
}
.child-pad-r-1.nl > *:last-child {
  padding-right: initial !important;
}

.child-pad-1-i > * {
  padding: 8px !important;
}

.child-pad-v-1-i > * {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.child-pad-h-2-i > * {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.child-pad-b-1-i > * {
  padding-bottom: 8px !important;
}
.child-pad-b-2-i > * {
  padding-bottom: 16px !important;
}
.child-pad-b-3-i > * {
  padding-bottom: 24px !important;
}
