/************************/
/* Element Self Margin */
/************************/

/* Overall margin */

.mar-0 {
  margin: 0px;
}
.mar-1 {
  margin: 8px;
}
.mar-2 {
  margin: 16px;
}
.mar-3,
.container {
  margin: 24px;
}

.mar-1-i {
  margin: 8px !important;
}
.mar-2-i {
  margin: 16px !important;
}
.mar-3-i {
  margin: 24px !important;
}

/* Top margin */
.mar-t-1\/2 {
  margin-top: 4px;
}
.mar-t-1 {
  margin-top: 8px;
}
.mar-t-2 {
  margin-top: 16px;
}
.mar-t-3 {
  margin-top: 24px;
}

/* Left margin */
.mar-l-1 {
  margin-left: 8px;
}
.mar-l-2 {
  margin-left: 16px;
}
.mar-l-2-i {
  margin-left: 16px !important;
}
.mar-l-3 {
  margin-left: 24px;
}

/* Bottom margin */
.mar-b-0 {
  margin-bottom: 0px;
}
.mar-b-1\/2 {
  margin-bottom: 4px;
}
.mar-b-1 {
  margin-bottom: 8px;
}

.mar-b-1-i {
  margin-bottom: 8px !important;
}

.mar-b-2 {
  margin-bottom: 16px;
}
.mar-b-2-i {
  margin-bottom: 16px !important;
}
.mar-b-3 {
  margin-bottom: 24px;
}

/* Right margin */
.mar-r-0 {
  margin-right: 0px;
}
.mar-r-1\/2 {
  margin-right: 4px;
}
.mar-r-1 {
  margin-right: 8px;
}
.mar-r-2 {
  margin-right: 16px;
}
.mar-r-2-i {
  margin-right: 16px !important;
}
.mar-r-3 {
  margin-right: 24px;
}

/* Horizontal margin */
.mar-h-1 {
  margin-right: 8px;
  margin-left: 8px;
}
.mar-h-2 {
  margin-right: 16px;
  margin-left: 16px;
}
.mar-h-3 {
  margin-right: 24px;
  margin-left: 24px;
}

/* Vertical margin */
.mar-v-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.mar-v-1 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.mar-v-1-i {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.mar-v-2 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mar-v-3 {
  margin-top: 24px;
  margin-bottom: 24px;
}

/*************************/
/* Element Child Margin */
/*************************/
.child-mar-t-1 > * {
  margin-top: 8px !important;
}
.child-mar-t-2 > * {
  margin-top: 16px !important;
}
.child-mar-t-3 > * {
  margin-top: 24px !important;
}

.child-mar-l-1 > * {
  margin-left: 8px !important;
}
.child-mar-l-2 > * {
  margin-left: 16px !important;
}
.child-mar-l-3 > * {
  margin-left: 24px !important;
}

.child-mar-r-1 > * {
  margin-right: 8px !important;
}
.child-mar-r-2 > * {
  margin-right: 16px !important;
}
.child-mar-r-3 > * {
  margin-right: 24px !important;
}

.child-mar-r-1 > * {
  margin-right: 8px !important;
}
.child-mar-r-2 > * {
  margin-right: 16px !important;
}
.child-mar-r-3 > * {
  margin-right: 24px !important;
}

.child-mar-r-1.nl > *:last-child {
  margin-right: initial !important;
}
.child-mar-r-2.nl > *:last-child {
  margin-right: initial !important;
}
.child-mar-r-3.nl > *:last-child {
  margin-right: initial !important;
}

.child-mar-b-1 > * {
  margin-bottom: 8px !important;
}
.child-mar-b-2 > * {
  margin-bottom: 16px !important;
}
.child-mar-b-3 > * {
  margin-bottom: 24px !important;
}

.child-mar-b-1.nl > *:last-child {
  margin-bottom: initial !important;
}
.child-mar-b-2.nl > *:last-child {
  margin-bottom: initial !important;
}
.child-mar-b-3.nl > *:last-child {
  margin-bottom: initial !important;
}

.mar-b-0-i {
  margin-bottom: 0 !important;
}
