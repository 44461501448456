$gaps: (
  //
  1: 4px,
  2: 8px,
  3: 16px,
  4: 24px,
  5: 32px,
  6: 64px,
  7: 94px
) !default;

$variables: (
  //
  gaps: $gaps,
  shp: (
    //
    default: 2px
  ),
  clr: (
    //
    default: #000000,
    borders: #d9d9d9,
    borders-dark: #969696,
    borders-light: #fafafa,
    primary: #69b28f,
    primary-dark: #538d71,
    primary-light: #7fbfa0,
    secondary: #6272af,
    secondary-dark: #485481,
    secondary-light: #7c8bc7,
    warning: #faad14,
    error: #ff4d4f,
    danger: #ff4d4f,
    danger-dark: #ee393c,
    danger-light: #f37f81,
    background: #fefefe
  ),
  ff: (
    //
    default: 'sans-serif'
  )
);

%disabled {
  opacity: 0.5;
  pointer-events: none;
}

%label {
  cursor: pointer;
  font-weight: 700;
  user-select: none;
  margin-bottom: 0.5em;
}

@import './flex.scss';
@import 'biuty/core';
@import './colors.scss';
@import '../pages/styles.scss';
@import '../components/styles.scss';
@import './admin.scss';
@import './texts.scss';
@import './margins.scss';
@import './paddings.scss';
@import './misc.scss';
@import './selfcity-theme.scss';
@import './ant.override.scss';
@import './buttons.scss';
@import './select.scss';
@import './scrollbars.scss';

.version-banner {
  top: 0;
  width: 100%;
  z-index: 10;
  position: fixed;
  color: #ffffff;
  font-weight: 400;
  font-size: 1.2rem;
  text-align: center;
  background: #ff0000a1;

  a {
    text-decoration: underline;
  }
}

* {
  scroll-behavior: initial;
  word-break: initial;
}

.ant-layout-sider-zero-width-trigger:after {
  pointer-events: none;
}

.ant-table-cell a {
  pointer-events: none;
}

.lazy-options {
  position: relative;

  &:focus-within .ui-options__wrapper__list {
    display: block;
  }

  .ui-textfield {
    z-index: 1;
  }
}
