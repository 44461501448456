* {
  /* Foreground, Background */
  scrollbar-color: transparent #bbb;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 5px; /* Mostly for vertical scrollbars */
  height: 5px; /* Mostly for horizontal scrollbars */
}
*::-webkit-scrollbar-thumb {
  /* Foreground */
  background: transparent;
  border-radius: 5px;
}
*::-webkit-scrollbar-track {
  /* Background */
  background: transparent;
  border-radius: 5px;
}

*:hover::-webkit-scrollbar-thumb {
  /* Foreground */
  background: #bbb;
  border-radius: 5px;
}
