.inbox-tasks-tags-container {
  margin-top: 5px;
}

.task-tag {
  @include extend(clickable);

  &__disabled {
    @include extend(disabled);
    color: grey !important;
    background-color: lightgrey !important;
    border-color: transparent !important;
  }

  &.ant-tag-checkable-checked {
    color: white !important;
    background-color: var(--sc-green-hex) !important;
    border-color: transparent !important;

    &.ant-tag-checkable:hover {
      color: white !important;
      background-color: var(--sc-green-rgba-8) !important;
      border-color: transparent !important;
    }

    &.ant-tag-checkable:active {
      color: white !important;
      background-color: var(--sc-green-rgba-6) !important;
      border-color: transparent !important;
    }
  }

  &.ant-tag-checkable:not(.ant-tag-checkable-checked) {
    color: var(--sc-green-hex);
    background-color: var(--sc-green-rgba-1);
    border-color: transparent;

    &.ant-tag-checkable:hover {
      color: white !important;
      background-color: var(--sc-green-rgba-5) !important;
      border-color: transparent !important;
    }

    &.ant-tag-checkable:active {
      color: white !important;
      background-color: var(--sc-green-rgba-7) !important;
      border-color: transparent !important;
    }
  }
}
