.estimate-modal .comments,
.estimate-supplies,
#root__0__requiredToolsIds,
#root__0__requiresTruck {
  padding: var(--gaps-3) 0;
  padding: var(--gaps-4) var(--gaps-2);
  margin-left: calc(-1 * var(--gaps-2));
  width: calc(100% + 2 * var(--gaps-2));
  max-width: calc(100% + 2 * var(--gaps-2));
  background: rgba(var(--clr-secondary-rgb), 0.05);
}
.estimate-modal .comments,
.estimate-supplies,
#root__0__requiredToolsIds {
  border-radius: var(shp-default);
}

.estimate-modal {
  overflow-x: hidden;
}
