@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.contact-card {
  flex: 1;
  min-width: 260px;
  max-width: 260px;

  .show-on-hover-flex,
  .ant-typography-copy,
  .anticon-copy {
    display: none;
  }

  &:hover .show-on-hover-flex,
  &:hover .ant-typography-copy,
  &:hover .anticon-copy {
    display: inline-flex;
    align-items: center;
    animation: fade-in 0.5s;
  }
}

.card-actions {
  position: absolute;
  right: 8px !important;
  top: 8px !important;

  button {
    padding-inline: 4px;
  }
}

.ant-tag {
  margin-right: 5px;
  margin-bottom: 5px;
}

.card-tag-checkable.checked {
  color: white !important;
  background-color: var(--sc-green-hex) !important;
  border-color: transparent !important;

  &.ant-tag-checkable:hover {
    color: white !important;
    background-color: var(--sc-green-rgba-8) !important;
    border-color: transparent !important;
  }

  &.ant-tag-checkable:active {
    color: white !important;
    background-color: var(--sc-green-rgba-6) !important;
    border-color: transparent !important;
  }
}

.card-tag-checkable.not-checked {
  color: var(--sc-green-hex);
  background-color: white;
  border-color: var(--sc-green-hex);

  &.ant-tag-checkable:hover {
    color: var(--sc-green-hex);
    background-color: var(--sc-green-rgba-1);
    border-color: transparent;
  }

  &.ant-tag-checkable:active {
    color: white !important;
    background-color: var(--sc-green-rgba-7) !important;
    border-color: transparent !important;
  }
}

.card-tag-checkable.checked.blue {
  color: white !important;
  background-color: var(--sc-blue-hex) !important;
  border-color: transparent !important;

  &.ant-tag-checkable:hover {
    color: white !important;
    background-color: var(--sc-blue-rgba-8) !important;
    border-color: transparent !important;
  }

  &.ant-tag-checkable:active {
    color: white !important;
    background-color: var(--sc-blue-rgba-6) !important;
    border-color: transparent !important;
  }
}

.card-tag-checkable.not-checked.blue {
  color: var(--sc-blue-hex);
  background-color: white;
  border-color: var(--sc-blue-hex);

  &.ant-tag-checkable:hover {
    color: var(--sc-blue-hex);
    background-color: var(--sc-blue-rgba-1);
    border-color: transparent;
  }

  &.ant-tag-checkable:active {
    color: white !important;
    background-color: var(--sc-blue-rgba-7) !important;
    border-color: transparent !important;
  }
}
