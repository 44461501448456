/**
 * Global page layout.
 */
.inbox-layout {
  height: 100%;
  padding: var(--gaps-2) var(--gaps-4);
}

.inbox-page {
  height: 100%;
  @include extend(flex flex-auto flex-col vgap-3);

  > .grid {
    flex: 1 1 auto;
    overflow: auto;
  }

  &__mode {
    padding: 1em;
    flex: 0 1 auto;
    background: #ffffff;

    span:first-of-type {
      font-weight: 600;
    }
  }

  &__unassigned-tasks,
  &__assigned-tasks {
    overflow: hidden;
    position: relative;
    @include extend(flex flex-col vgap-3);
    &__filters {
      padding: 1em;
      font-weight: 600;
      @include extend(
        grid cols-1 wrap s\:flex l\:grid l\:cols-1 xl\:flex vgap-3 hgap-5
      );

      > div {
        @include extend(s\:flex-auto m\:flex-none);
      }
    }

    > div:last-of-type {
      flex: 0 1 auto;
      overflow: auto;
      padding-bottom: var(--gaps-2);
    }
  }

  &__unassigned-tasks {
    display: none;
    background: #ffffff;

    .inbox-task {
      margin: 0 var(--gaps-3);
    }
  }

  .ant-select {
    min-width: 150px;
  }

  @include query(l) {
    &__assigned-tasks {
      display: flex;
    }
    &__unassigned-tasks {
      display: flex;
    }
  }

  @include modifier(unassigned, &) {
    &__mode span:first-of-type {
      font-weight: 400;
    }

    &__mode span:last-of-type {
      font-weight: 600;
    }

    &__assigned-tasks {
      display: none;
    }

    &__unassigned-tasks {
      display: flex;
    }
  }
}

/**
 * Task card.
 */
.inbox-task {
  opacity: 1;
  padding: 0.8rem;
  background: #ffffff;
  animation: fade-in 0.5s;
  border: 1px solid #d9d9d9;
  transition: all 0.2s ease-out;
  border-radius: 5px;
  @include extend(flex items-center justify-between hgap-3 mar-b-1);
  position: relative;

  &__details {
    overflow: hidden;

    &__tag {
      color: var(--sc-green-hex);
      background-color: transparent;
      border-color: var(--sc-green-hex);
    }

    &__title,
    &__client-request,
    &__assignee,
    &__assign,
    &__due-date,
    &__title {
      font-size: 14px;
      white-space: normal;
    }

    &__client-request,
    &__assignee,
    &__assign {
      font-size: 0.9em;
      cursor: pointer;
      color: var(--ant-primary-color);
    }
    &__assignee {
      cursor: default;
    }

    &__due-date {
      color: #acacac;
      margin-right: 1rem;
    }

    &__edit_btn {
      position: absolute;
      right: 20px;
      top: 10px;
      @include extend(none green-btn);
      background-color: white !important;
    }

    &__assign_btn {
      position: absolute;
      right: -16px;
      top: calc(50% - 16px);
      @include extend(none blue-btn);

      &__assigned {
        right: -16px;
      }
    }
  }

  &:hover .inbox-task__details__assign_btn {
    @include extend(block);
  }

  &:hover .inbox-task__details__edit_btn {
    @include extend(block);
  }

  & > .fab-item {
    margin: 0;
    width: 30px;
    min-width: 30px;
    position: initial;
    position: relative;
    opacity: 1 !important;
    height: 30px !important;
    transform: none !important;
    &:after {
      display: none !important;
    }
    &:last-child {
      .anticon {
        font-size: 30px;
        color: var(--sc-blue-hex);
      }
    }
  }

  .fab-item {
    box-shadow: none;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  @include modifier(unassigned) {
    .inbox-task__details {
      order: 1;
    }
    & > .fab-item {
      order: 0;
    }
  }

  @include modifier(unassigned, &) {
    &__details__assign_btn {
      left: -16px;
    }
  }

  @include modifier(assigned) {
    opacity: 0;
    transform: translateX(-200px);
  }

  @include modifier(outdated) {
    background: #ff000010;
  }

  @include modifier(isDueDateDay) {
    background: var(--sc-yellow-rgba-2);
  }

  @include modifier(deassigned) {
    opacity: 0;
    transform: translateX(200px);
  }

  @include modifier(deassigned) {
    opacity: 0;
    transform: translateX(200px);
  }

  @include modifier(done) {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    background: var(--ant-primary-color);
  }
}
