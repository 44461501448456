.ant-timeline-item-head {
  background-color: transparent;
}

// .ant-timeline-item-head-custom {
//   top: 11px;
// }

.ant-radio-wrapper.full-w > span:last-child,
.ant-checkbox-wrapper.full-w > span:last-child {
  width: 100%;
}

// .image-preview-group-wrapper {
//   .ant-image:not(:last-child) {
//     margin-right: 4px;
//     margin-bottom: 4px;
//   }
// }

.ant-switch.ant-switch-checked.archiving-btn {
  background-color: orangered;
}
