.italic {
  font-style: italic;
}

.fw-bold,
.fw-500 {
  font-weight: bold;
}
.fw-450 {
  font-weight: 450;
}
.fw-normal,
.fw-400 {
  font-weight: normal;
}
.fw-350 {
  font-weight: 350;
}
.fw-light,
.fw-300 {
  font-weight: 300;
}

.fs-xxs {
  font-size: 0.25rem;
}
.fs-xs {
  font-size: 0.5rem;
}
.fs-s {
  font-size: 0.75rem;
}
.fs-1 {
  font-size: 1rem;
}
.fs-2 {
  font-size: 1.25rem;
}
.fs-3 {
  font-size: 1.5rem;
}
.fs-4 {
  font-size: 1.75rem;
}
.fs-5 {
  font-size: 2rem;
}
.fs-6 {
  font-size: 2.5rem;
}
.fs-7 {
  font-size: 3rem;
}

.ta-center {
  text-align: center;
}
.ta-left {
  text-align: left;
}
.ta-right {
  text-align: right;
}

.display-linebreak {
  white-space: pre-line;
}

.no-linebreak {
  white-space: pre;
}

.italic {
  font-style: italic;
}

.grey {
  color: grey;
}

.fc-white {
  color: white;
}

.fc-primary {
  color: var(--sc-green-hex);
}

.f-spacing-4 {
  letter-spacing: 4px;
}

.fc-inherit {
  color: inherit !important;
}

.txt-underline {
  text-decoration: underline;
}
