.page-profiles-create {
  // Go crazy!
}

.input-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
  .ant-form-item {
    flex-grow: 1;
    //   // width: 180px;
  }
}
