.job-estimation-status-wrapper > * {
  min-width: 160px;
}

.je-kanban-card-title {
  font-size: 16px;
  font-weight: bold;
}

.je-kanban-card-tag-div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.je-kanban-card-tag {
  white-space: nowrap;
  .icon {
    font-size: 12px;
  }
  .text {
    margin-left: 7px;
    font-size: 12px;
    white-space: nowrap;
  }
}

.fc-sc-black {
  color: black;
}
