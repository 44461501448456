.page-client-requests-list {
  // Go crazy!
}

.search-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 12px;
}

.table {
  overflow: auto;

  .ant-pagination-options {
    display: none;
  }
}
