.main-timeline {
  height: calc(
    100vh - (64px + 8 * 2px + 32px + 72px + 17px + 8px + 4px)
  ); // 100% -(header + mainPadding + btn + commentInput + dividerWithMargins + timelinePadding)
  overflow: auto;
}

.timeline-filter-tag {
  &.ant-tag-checkable-checked,
  &.ant-tag-checkable:active {
    color: transparent !important;
    background-color: transparent !important;
  }
}
