.kanban-collapse {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  // gap: 8px;
}

.kanban-column {
  min-width: 315px;
  flex: 1 1 22%;
  .ant-collapse-header {
    padding: 4px 16px 4px 16px !important;
  }
  .ant-collapse-arrow {
    padding-right: 10px;
  }

  & > div:first-child {
    .ant-collapse-header-text {
      flex: 1 1 auto;
    }
  }
}

.column-title {
  padding: 0 2rem;
  display: flex;

  justify-content: center;
  font-weight: bold;
  text-align: center;
  width: 100%;
  height: 30px;
  line-height: 30px;
}

.column-card {
}

@media (min-width: 570px) {
  .kanban-collapse {
    flex-wrap: nowrap;
  }
}

.ant-card .show-on-hover {
  visibility: hidden;
}

.ant-card:hover .show-on-hover {
  visibility: visible;
}

.plop {
  width: 100% !important;
}
