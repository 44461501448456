.ant-btn.green-btn:not(:disabled),
.ant-btn-group.green-btn:not(:disabled) > .ant-btn:not(:disabled) {
  &:not(.ant-btn-link) {
    border-color: var(--sc-green-rgb);
  }

  &:not(.ant-btn-primary) {
    color: var(--sc-green-rgb);

    &:hover {
      &:not(.ant-btn-link) {
        border-color: var(--sc-green-rgba-6) !important;
      }
      color: var(--sc-green-rgba-6) !important;
    }
  }

  &.ant-btn-primary {
    background-color: var(--sc-green-rgb);
    color: white;

    &:hover {
      background-color: var(--sc-green-rgba-6) !important;
    }
  }
}

.ant-btn.eggplant-btn:not(:disabled),
.ant-btn-group.eggplant-btn > .ant-btn:not(:disabled) {
  &:not(.ant-btn-link) {
    border-color: var(--sc-eggplant-rgb);
  }

  &:not(.ant-btn-primary) {
    color: var(--sc-eggplant-rgb);

    &:hover {
      &:not(.ant-btn-link) {
        border-color: var(--sc-eggplant-rgba-6) !important;
      }
      color: var(--sc-eggplant-rgba-6) !important;
    }
  }

  &.ant-btn-primary {
    background-color: var(--sc-eggplant-rgb);
    color: white;

    &:hover {
      background-color: var(--sc-eggplant-rgba-6) !important;
    }
  }
}

.blue-btn:not(.disabled),
.ant-btn.blue-btn:not(:disabled),
.ant-btn.blue-btn:not(.disabled),
.ant-btn-group.blue-btn:not(:disabled) > .ant-btn:not(:disabled) {
  &:not(.ant-btn-link) {
    border-color: var(--sc-blue-rgb);
  }

  &:not(.ant-btn-primary) {
    color: var(--sc-blue-rgb);

    &:hover {
      &:not(.ant-btn-link) {
        border-color: var(--sc-blue-rgba-6) !important;
      }
      color: var(--sc-blue-rgba-6) !important;
    }
  }

  &.ant-btn-primary {
    background-color: var(--sc-blue-rgb);
    color: white;

    &:hover {
      background-color: var(--sc-blue-rgba-6) !important;
    }
  }
}
