.green-modal-head {
  font-weight: bold;
  color: #69b28f;
  max-width: 80%;
  padding: 0;
}

.custom-modal {
  .ant-modal-body {
    padding: 0px;
  }
}

.ant-modal-content {
  padding: 1em 3em;
}
