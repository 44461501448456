.page-client-requests-edit {
  display: flex !important;
  .flex {
    display: flex !important;
  }
}

.inbox-modal {
  > .input-row:nth-child(3) {
    display: none;
  }
}

.follow-ups-card {
  background-color: var(--ant-warning-color-deprecated-bg);
  border: 1px solid var(--ant-warning-color-deprecated-border);

  .ant-card-body {
    @include extend(flex flex-auto items-center);
    * {
      margin: 0;
    }
  }
}

.cards-div {
  flex: 1 1 auto;
  max-height: calc(100vh - (64px + 2 * 8px)); // 100% -(header + 2*padding)
  overflow: auto;
}

.timeline {
  max-height: calc(100vh - (64px + 2 * 8px)); // 100% -(header + 2*padding)
  overflow: auto;
}

.timeline.flex-8 {
  width: 35%;

  .timeline-comment-img {
    display: block !important;
    position: relative;

    a {
      display: inline-block;
      max-width: 65%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 1;
      vertical-align: middle;
    }
  }
}

.ant-tooltip {
  max-width: none;
}