body {
  color: #4a3557;
}

.x-ui-admin {
  .logo {
    height: 32px;
    margin: 16px;
    text-align: center;

    img {
      height: 32px;
    }
  }
  .x-ui-admin-sider {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    .x-ui-admin-sider-menu {
      height: calc(100vh - 64px);
      overflow: auto;
    }
  }

  .x-ui-admin-layout {
    // height: 100vh;
    // overflow: auto;

    .x-ui-admin-header {
      background: #fff;
      padding: 0;
      // text-align: right;
      padding-right: 16px;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      flex-wrap: nowrap;
    }

    .x-ui-admin-content {
      max-height: calc(100vh - (64px + 2 * 8px)); // 100%-(header+2*padding)
      overflow: auto;
    }
  }

  .x-ui-admin-footer {
    font-family: monospace;
    color: #555;
    text-transform: uppercase;
  }
}

.ant-descriptions-item-label {
  font-weight: 500;
}

@media (max-width: 569px) {
  .page-header {
    margin-left: 33px;
    z-index: 0;
    position: inherit;
  }
}

.sider-trigger {
  position: inherit;
  top: -6px;
  left: 0px;
  z-index: 10px;
}

@media (min-width: 570px) {
  .sider-trigger {
    display: none;
  }
}
