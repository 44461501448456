.comment-btns {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: space-around;
  margin-left: 8px;
  gap: 8px;
}

.comment-btns-one-line {
  flex-direction: row;
}

.comment-btns-two-lines {
  flex-direction: column;
}

.timeline-comment-img {
  .ant-upload-list-picture-card-container {
    margin-bottom: 0px;
  }
}
