.flex {
  display: flex;
}

.flex.center {
  justify-content: center;
  align-items: center;
}

.flex.j-center {
  justify-content: center;
}
.flex.a-center {
  align-items: center;
}
.flex.a-start {
  align-items: flex-start;
}
.flex.spaced {
  justify-content: space-between;
}
.flex.reverse {
  flex-direction: row-reverse;
}
.flex.around {
  justify-content: space-around;
}
.flex.column {
  flex-direction: column;
}
.flex.column.reverse {
  flex-direction: column-reverse;
}
.flex.j-end {
  justify-content: flex-end;
}
.flex.wrap {
  flex-wrap: wrap;
}

.self-center {
  align-self: center;
}

@media (max-width: 960px) {
  .flex.sm-wrap {
    flex-wrap: wrap;
  }
}

.grow {
  flex-grow: 1;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.flex-8 {
  flex: 8;
}

.flex-9 {
  flex: 9;
}

.flex-10 {
  flex: 10;
}
.flex-11 {
  flex: 11;
}

.flex-12 {
  flex: 12;
}

.flex-13 {
  flex: 13;
}

.flex-14 {
  flex: 14;
}

.flex-15 {
  flex: 15;
}

.gap-1\/2 {
  gap: 4px;
}

.gap-1 {
  gap: 8px !important;
}

.gap-2 {
  gap: 16px;
}

.gap-3 {
  gap: 24px;
}

.gap-4 {
  gap: 32px;
}

.gap-5 {
  gap: 40px !important;
}
