body {
  // Opacities
  --opacity-1: 0.1;
  --opacity-2: 0.2;
  --opacity-3: 0.3;
  --opacity-4: 0.4;
  --opacity-5: 0.5;
  --opacity-6: 0.6;
  --opacity-7: 0.7;
  --opacity-8: 0.8;
  --opacity-9: 0.9;

  // Selfcity Green
  --sc-green-hex: #69b28f;
  --sc-green-rgba-1: rgba(105, 178, 143, var(--opacity-1));
  --sc-green-rgba-2: rgba(105, 178, 143, var(--opacity-2));
  --sc-green-rgba-3: rgba(105, 178, 143, var(--opacity-3));
  --sc-green-rgba-4: rgba(105, 178, 143, var(--opacity-4));
  --sc-green-rgba-5: rgba(105, 178, 143, var(--opacity-5));
  --sc-green-rgba-6: rgba(105, 178, 143, var(--opacity-6));
  --sc-green-rgba-7: rgba(105, 178, 143, var(--opacity-7));
  --sc-green-rgba-8: rgba(105, 178, 143, var(--opacity-8));
  --sc-green-rgba-9: rgba(105, 178, 143, var(--opacity-9));
  --sc-green-rgb: rgba(105, 178, 143);

  // Selfcity Red
  --sc-red-hex: #292425;
  --sc-red-rgba-1: rgba(245, 34, 45, var(--opacity-1));
  --sc-red-rgba-2: rgba(245, 34, 45, var(--opacity-2));
  --sc-red-rgba-3: rgba(245, 34, 45, var(--opacity-3));
  --sc-red-rgba-4: rgba(245, 34, 45, var(--opacity-4));
  --sc-red-rgba-5: rgba(245, 34, 45, var(--opacity-5));
  --sc-red-rgba-6: rgba(245, 34, 45, var(--opacity-6));
  --sc-red-rgba-7: rgba(245, 34, 45, var(--opacity-7));
  --sc-red-rgba-8: rgba(245, 34, 45, var(--opacity-8));
  --sc-red-rgba-9: rgba(245, 34, 45, var(--opacity-9));
  --sc-red-rgb: rgba(245, 34, 45, 1);

  // Selfcity Yellow
  --sc-yellow-hex: #f5c97c;
  --sc-yellow-rgba-1: rgba(245, 201, 124, var(--opacity-1));
  --sc-yellow-rgba-2: rgba(245, 201, 124, var(--opacity-2));
  --sc-yellow-rgba-3: rgba(245, 201, 124, var(--opacity-3));
  --sc-yellow-rgba-4: rgba(245, 201, 124, var(--opacity-4));
  --sc-yellow-rgba-5: rgba(245, 201, 124, var(--opacity-5));
  --sc-yellow-rgba-6: rgba(245, 201, 124, var(--opacity-6));
  --sc-yellow-rgba-7: rgba(245, 201, 124, var(--opacity-7));
  --sc-yellow-rgba-8: rgba(245, 201, 124, var(--opacity-8));
  --sc-yellow-rgba-9: rgba(245, 201, 124, var(--opacity-9));
  --sc-yellow-rgb: rgba(245, 201, 124, 1);

  // Selfcity Blue
  --sc-blue-hex: #6272af;
  --sc-blue-rgba-1: rgba(98, 114, 175, var(--opacity-1));
  --sc-blue-rgba-2: rgba(98, 114, 175, var(--opacity-2));
  --sc-blue-rgba-3: rgba(98, 114, 175, var(--opacity-3));
  --sc-blue-rgba-4: rgba(98, 114, 175, var(--opacity-4));
  --sc-blue-rgba-5: rgba(98, 114, 175, var(--opacity-5));
  --sc-blue-rgba-6: rgba(98, 114, 175, var(--opacity-6));
  --sc-blue-rgba-7: rgba(98, 114, 175, var(--opacity-7));
  --sc-blue-rgba-8: rgba(98, 114, 175, var(--opacity-8));
  --sc-blue-rgba-9: rgba(98, 114, 175, var(--opacity-9));
  --sc-blue-rgb: rgba(98, 114, 175, 1);

  // Selfcity Eggplant
  --sc-eggplant-hex: #4a3557;
  --sc-eggplant-rgba-1: rgba(74, 53, 87, var(--opacity-1));
  --sc-eggplant-rgba-2: rgba(74, 53, 87, var(--opacity-2));
  --sc-eggplant-rgba-3: rgba(74, 53, 87, var(--opacity-3));
  --sc-eggplant-rgba-4: rgba(74, 53, 87, var(--opacity-4));
  --sc-eggplant-rgba-5: rgba(74, 53, 87, var(--opacity-5));
  --sc-eggplant-rgba-6: rgba(74, 53, 87, var(--opacity-6));
  --sc-eggplant-rgba-7: rgba(74, 53, 87, var(--opacity-7));
  --sc-eggplant-rgba-8: rgba(74, 53, 87, var(--opacity-8));
  --sc-eggplant-rgba-9: rgba(74, 53, 87, var(--opacity-9));
  --sc-eggplant-rgb: rgba(74, 53, 87, 1);

  // Empty Card Color
  --empty-card-color: rgba(0, 0, 0, 0.25);
}

// ---------------------------------
// -----  BACKGROUND COLORS  -------
// ---------------------------------

// Selfcity Green
.bg-sc-green-1 {
  background-color: var(--sc-green-rgba-1);
}
.bg-sc-green-2 {
  background-color: var(--sc-green-rgba-2);
}
.bg-sc-green-3 {
  background-color: var(--sc-green-rgba-3);
}
.bg-sc-green-4 {
  background-color: var(--sc-green-rgba-4);
}
.bg-sc-green-5 {
  background-color: var(--sc-green-rgba-5);
}
.bg-sc-green-6 {
  background-color: var(--sc-green-rgba-6);
}
.bg-sc-green-7 {
  background-color: var(--sc-green-rgba-7);
}
.bg-sc-green-8 {
  background-color: var(--sc-green-rgba-8);
}
.bg-sc-green-9 {
  background-color: var(--sc-green-rgba-9);
}
.bg-sc-green {
  background-color: var(--sc-green-rgb);
}

// Selfcity Red
.bg-sc-red-1 {
  background-color: var(--sc-red-rgba-1);
}
.bg-sc-red-2 {
  background-color: var(--sc-red-rgba-2);
}
.bg-sc-red-3 {
  background-color: var(--sc-red-rgba-3);
}
.bg-sc-red-4 {
  background-color: var(--sc-red-rgba-4);
}
.bg-sc-red-5 {
  background-color: var(--sc-red-rgba-5);
}
.bg-sc-red-6 {
  background-color: var(--sc-red-rgba-6);
}
.bg-sc-red-7 {
  background-color: var(--sc-red-rgba-7);
}
.bg-sc-red-8 {
  background-color: var(--sc-red-rgba-8);
}
.bg-sc-red-9 {
  background-color: var(--sc-red-rgba-9);
}
.bg-sc-red {
  background-color: var(--sc-red-rgb);
}

// Selfcity Yellow
.bg-sc-yellow-1 {
  background-color: var(--sc-yellow-rgba-1);
}
.bg-sc-yellow-2 {
  background-color: var(--sc-yellow-rgba-2);
}
.bg-sc-yellow-3 {
  background-color: var(--sc-yellow-rgba-3);
}
.bg-sc-yellow-4 {
  background-color: var(--sc-yellow-rgba-4);
}
.bg-sc-yellow-5 {
  background-color: var(--sc-yellow-rgba-5);
}
.bg-sc-yellow-6 {
  background-color: var(--sc-yellow-rgba-6);
}
.bg-sc-yellow-7 {
  background-color: var(--sc-yellow-rgba-7);
}
.bg-sc-yellow-8 {
  background-color: var(--sc-yellow-rgba-8);
}
.bg-sc-yellow-9 {
  background-color: var(--sc-yellow-rgba-9);
}
.bg-sc-yellow {
  background-color: var(--sc-yellow-rgb);
}

// Selfcity Blue
.bg-sc-blue-1 {
  background-color: var(--sc-blue-rgba-1);
}
.bg-sc-blue-2 {
  background-color: var(--sc-blue-rgba-2);
}
.bg-sc-blue-3 {
  background-color: var(--sc-blue-rgba-3);
}
.bg-sc-blue-4 {
  background-color: var(--sc-blue-rgba-4);
}
.bg-sc-blue-5 {
  background-color: var(--sc-blue-rgba-5);
}
.bg-sc-blue-6 {
  background-color: var(--sc-blue-rgba-6);
}
.bg-sc-blue-7 {
  background-color: var(--sc-blue-rgba-7);
}
.bg-sc-blue-8 {
  background-color: var(--sc-blue-rgba-8);
}
.bg-sc-blue-9 {
  background-color: var(--sc-blue-rgba-9);
}
.bg-sc-blue {
  background-color: var(--sc-blue-rgb);
}

// Selfcity Eggplant
.bg-sc-eggplant-1 {
  background-color: var(--sc-eggplant-rgba-1);
}
.bg-sc-eggplant-2 {
  background-color: var(--sc-eggplant-rgba-2);
}
.bg-sc-eggplant-3 {
  background-color: var(--sc-eggplant-rgba-3);
}
.bg-sc-eggplant-4 {
  background-color: var(--sc-eggplant-rgba-4);
}
.bg-sc-eggplant-5 {
  background-color: var(--sc-eggplant-rgba-5);
}
.bg-sc-eggplant-6 {
  background-color: var(--sc-eggplant-rgba-6);
}
.bg-sc-eggplant-7 {
  background-color: var(--sc-eggplant-rgba-7);
}
.bg-sc-eggplant-8 {
  background-color: var(--sc-eggplant-rgba-8);
}
.bg-sc-eggplant-9 {
  background-color: var(--sc-eggplant-rgba-9);
}
.bg-sc-eggplant {
  background-color: var(--sc-eggplant-rgb);
}

// ---------------------------------
// -----  TEXT COLORS  -------------
// ---------------------------------

// Selfcity Green
.fc-sc-green-1 {
  color: var(--sc-green-rgba-1);
}
.fc-sc-green-2 {
  color: var(--sc-green-rgba-2);
}
.fc-sc-green-3 {
  color: var(--sc-green-rgba-3);
}
.fc-sc-green-4 {
  color: var(--sc-green-rgba-4);
}
.fc-sc-green-5 {
  color: var(--sc-green-rgba-5);
}
.fc-sc-green-6 {
  color: var(--sc-green-rgba-6);
}
.fc-sc-green-7 {
  color: var(--sc-green-rgba-7);
}
.fc-sc-green-8 {
  color: var(--sc-green-rgba-8);
}
.fc-sc-green-9 {
  color: var(--sc-green-rgba-9);
}
.fc-sc-green {
  color: var(--sc-green-rgb);
}

// Selfcity Red
.fc-sc-red-1 {
  color: var(--sc-red-rgba-1);
}
.fc-sc-red-2 {
  color: var(--sc-red-rgba-2);
}
.fc-sc-red-3 {
  color: var(--sc-red-rgba-3);
}
.fc-sc-red-4 {
  color: var(--sc-red-rgba-4);
}
.fc-sc-red-5 {
  color: var(--sc-red-rgba-5);
}
.fc-sc-red-6 {
  color: var(--sc-red-rgba-6);
}
.fc-sc-red-7 {
  color: var(--sc-red-rgba-7);
}
.fc-sc-red-8 {
  color: var(--sc-red-rgba-8);
}
.fc-sc-red-9 {
  color: var(--sc-red-rgba-9);
}
.fc-sc-red {
  color: var(--sc-red-rgb);
}

// Selfcity Yellow
.fc-sc-yellow-1 {
  color: var(--sc-yellow-rgba-1);
}
.fc-sc-yellow-2 {
  color: var(--sc-yellow-rgba-2);
}
.fc-sc-yellow-3 {
  color: var(--sc-yellow-rgba-3);
}
.fc-sc-yellow-4 {
  color: var(--sc-yellow-rgba-4);
}
.fc-sc-yellow-5 {
  color: var(--sc-yellow-rgba-5);
}
.fc-sc-yellow-6 {
  color: var(--sc-yellow-rgba-6);
}
.fc-sc-yellow-7 {
  color: var(--sc-yellow-rgba-7);
}
.fc-sc-yellow-8 {
  color: var(--sc-yellow-rgba-8);
}
.fc-sc-yellow-9 {
  color: var(--sc-yellow-rgba-9);
}
.fc-sc-yellow {
  color: var(--sc-yellow-rgb);
}

// Selfcity Blue
.fc-sc-blue-1 {
  color: var(--sc-blue-rgba-1);
}
.fc-sc-blue-2 {
  color: var(--sc-blue-rgba-2);
}
.fc-sc-blue-3 {
  color: var(--sc-blue-rgba-3);
}
.fc-sc-blue-4 {
  color: var(--sc-blue-rgba-4);
}
.fc-sc-blue-5 {
  color: var(--sc-blue-rgba-5);
}
.fc-sc-blue-6 {
  color: var(--sc-blue-rgba-6);
}
.fc-sc-blue-7 {
  color: var(--sc-blue-rgba-7);
}
.fc-sc-blue-8 {
  color: var(--sc-blue-rgba-8);
}
.fc-sc-blue-9 {
  color: var(--sc-blue-rgba-9);
}
.fc-sc-blue {
  color: var(--sc-blue-rgb);
}

// Selfcity Eggplant
.fc-sc-eggplant-1 {
  color: var(--sc-eggplant-rgba-1);
}
.fc-sc-eggplant-2 {
  color: var(--sc-eggplant-rgba-2);
}
.fc-sc-eggplant-3 {
  color: var(--sc-eggplant-rgba-3);
}
.fc-sc-eggplant-4 {
  color: var(--sc-eggplant-rgba-4);
}
.fc-sc-eggplant-5 {
  color: var(--sc-eggplant-rgba-5);
}
.fc-sc-eggplant-6 {
  color: var(--sc-eggplant-rgba-6);
}
.fc-sc-eggplant-7 {
  color: var(--sc-eggplant-rgba-7);
}
.fc-sc-eggplant-8 {
  color: var(--sc-eggplant-rgba-8);
}
.fc-sc-eggplant-9 {
  color: var(--sc-eggplant-rgba-9);
}
.fc-sc-eggplant {
  color: var(--sc-eggplant-rgb);
}

// Empty Card Color
.fc-empty-card {
  color: var(--empty-card-color);
}