@import "./JobEstimationSumup/JobEstimationSumup.styles.scss";

@media (max-width: 569px) {
  .technician-select {
    display: flex;
  }
}

.dynamic-options {
  .ui-options {
    max-width: initial;

    @include modifier(warning) {
      color: var(--clr-warning);
      .ui-options__wrapper {
        border-color: var(--clr-warning);
      }
    }
  }
}
